import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/CalendarView.vue')
  },
  {
    path: '/judicial',
    name: 'listJudicial',
    component: () => import('../views/ListJudicialView.vue')
  },
  {
    path: '/alienacaofiduciaria',
    name: 'listAlienacaoFiduciaria',
    component: () => import('../views/ListAlienacaoFiduciariaView.vue')
  },
  {
    path: '/vendadireta',
    name: 'listVendaDireta',
    component: () => import('../views/ListVendaDiretaView.vue')
  },
  {
    path: '/presencial',
    name: 'listPresencial',
    component: () => import('../views/ListPresencialView.vue')
  },
  {
    path: '/alerte',
    name: 'listAlerte',
    component: () => import('../views/ListAlerteView.vue')
  },
  {
    path: '/preleilao',
    name: 'listPreleilao',
    component: () => import('../views/ListPreleilaoView.vue')
  },
  {
    path: '/regularizacao',
    name: 'listRegularizacao',
    component: () => import('../views/ListRegularizacaoView.vue')
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('../views/VisualizeLeilaoView.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/GerenciarPermissoesUsuarios.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
